import React, { useState } from 'react';
import styles from './Gallery.module.css';

import image1 from './GalleryImages/1.jpg';
import image2 from './GalleryImages/2.jpg';
import image3 from './GalleryImages/3.jpg';
import image4 from './GalleryImages/4.jpg';
import image5 from './GalleryImages/5.jpg';
import image6 from './GalleryImages/6.jpg';
import image7 from './GalleryImages/7.jpg';
import image8 from './GalleryImages/8.jpg';
import image9 from './GalleryImages/9.jpg';
import image10 from './GalleryImages/10.jpg';
import image11 from './GalleryImages/11.jpg';
import image12 from './GalleryImages/12.jpg';


const Gallery = () => {
    const images = [
      { src: image1, alt: 'Description 1' },
      { src: image2, alt: 'Description 2' },
      { src: image3, alt: 'Description 3' },
      { src: image4, alt: 'Description 4' },
      { src: image5, alt: 'Description 5' },
      { src: image6, alt: 'Description 6' },
      { src: image7, alt: 'Description 7' },
      { src: image8, alt: 'Description 8' },
      { src: image9, alt: 'Description 9' },
      { src: image10, alt: 'Description 10' },
      { src: image11, alt: 'Description 11' },
      { src: image12, alt: 'Description 12' },
    ];

    const [modalOpen, setModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const openModal = (index) => {
        setCurrentIndex(index);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const showPrevImage = (e) => {
        e.stopPropagation();
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const showNextImage = (e) => {
        e.stopPropagation();
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div className={styles.container}>
            <div className={styles.Title}>Gallery</div>
            <div className={styles.grid}>
              {images.map((img, index) => (
                <div key={index} className={styles.photo} onClick={() => openModal(index)}>
                  <img src={img.src} alt={img.alt} />
                </div>
              ))}
            </div>
            {modalOpen && (
                <div className={styles.modal} onClick={closeModal}>
                    <img src={images[currentIndex].src} alt="Expanded view" className={styles.modalContent} />
                    <span className={styles.close} onClick={closeModal}>&times;</span>
                    <button className={styles.prev} onClick={showPrevImage}>&#10094;</button>
                    <button className={styles.next} onClick={showNextImage}>&#10095;</button>
                </div>
            )}
        </div>
    );
};

export default Gallery;
