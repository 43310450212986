import React, { useEffect } from 'react';

const CalendlyWidget = () => {
  useEffect(() => {
    // Create a script element to include the Calendly widget script
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component unmounts
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <div
      className="calendly-inline-widget"
      data-url="https://calendly.com/mmckillen52/beauty-room-booking?text_color=530940&primary_color=530940"
      style={{ minWidth: '100%', height: '100%' }}
    ></div>
  );
};

export default CalendlyWidget;
