import React from 'react';
import styles from './Services.module.css'; // Ensure this is the correct path to your CSS file
import PriceList from '../assets/PriceListV2.png'; // Adjust path to the image as necessary

const Services = () => {
  return (
    <div className={styles.container}>

      <div className={styles.Title}>Service Price List</div>
      <img src={PriceList} alt="Descriptive Alt Text" className={styles.imageResponsive} />

     
    </div>
  );
};

export default Services;
