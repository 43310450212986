import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import BookingForm from './pages/BookingForm';
import Confirmation from './pages/Confirmation';
import Navbar from './components/Navbar';  // Import the Navbar component
import Services from './pages/Services';
import './global.css';
import Gallery from './pages/Gallery';
import About from './pages/About';


function App() {
  return (
    <Router>
      <Navbar />  
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/book" element={<BookingForm />} />
        <Route path="/confirm" element={<Confirmation />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/thankyou" element={<About />} />
        <Route path="/services" element={<Services />} />
      </Routes>
    </Router>
  );
}

export default App;
