function Confirmation() {
    return (
      <div>
        <h1>Booking Confirmed!</h1>
        <p>Your appointment is set. We look forward to serving you.</p>
      </div>
    );
  }
  
  export default Confirmation;
  