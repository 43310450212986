import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.css';
import logo from '../assets/logo.png';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);  // State to manage menu toggle

  return (
    <nav className={styles.navbar}>
      <div className={styles.navContainer}>
        <Link to="/" className={styles.navLogo}>
          <img src={logo} alt="Marti Aesthetics Logo" style={{ height: '150px' }} />
        </Link>
        <button className={styles.menuButton} onClick={() => setIsOpen(!isOpen)}>
          ☰  {/* Hamburger icon */}
        </button>
        <div className={isOpen ? `${styles.navLinks} ${styles.active}` : styles.navLinks}>
          {/* <Link to="/" className={styles.navItem} onClick={() => setIsOpen(false)}>Home</Link> */}
          <Link to="/book" className={styles.navItem} onClick={() => setIsOpen(false)}>Book Appointment</Link>
          <Link to="/gallery" className={styles.navItem} onClick={() => setIsOpen(false)}>Gallery</Link>
          <Link to="/services" className={styles.navItem} onClick={() => setIsOpen(false)}>Services</Link>
          <Link to="/thankyou" className={styles.navItem} onClick={() => setIsOpen(false)}>Thank you</Link>
          
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
