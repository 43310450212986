import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Home.module.css'; // Import the styles

function Home() {
  return (
    <div className={styles.container}>
      <div className={styles.Welcome}>Welcome to Marti Aesthetics</div>
      <Link to="/book" className={styles.linkButton}>Book an Appointment</Link>
    </div>
  );
}

export default Home;
