import React from 'react';
import styles from './BookingForm.module.css'; // Import styles at the top
import CalendlyWidget from '../components/CalendlyWidget'; // Ensure imports are before any other statements

function BookingForm() {
  return (
    <div className={styles.container}>  {/* Apply the container styles */}
      <div className={styles.Title}>Book Your Appointment</div>
      <CalendlyWidget />
    </div>
  );
}

export default BookingForm;
