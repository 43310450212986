import React from 'react';
import styles from './About.module.css'; // Ensure this is the correct path
import selfieImage from './GalleryImages/TinaSelfie.jpg'; // Adjust path to the image as necessary

const About = () => {
  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <img src={selfieImage} alt="Descriptive Alt Text" className={styles.imageResponsive} />
      </div>
      <div className={styles.rightSide}>
        <div className={styles.header}> A Message Of Appreciation</div>
        <p className={styles.text}>
        Thank you to all my OG clients. You all know who you are. It's amazing how word of mouth really works. I've had a lot of new clients which I am so grateful for🙏
        I've always wanted to do what I'm doing since way before I had my kids but just put everything else in front of it and so on. It wasn't until my Brother passed away 7 yrs ago that made me realise you only get one shot in life so have no regrets. I firmly believe my Brother Martin guided me in the right direction which is why I called my business after him. 
        Marti Aesthetic's 💕

        <p>When i receive DM's or texts callling me Marti it puts a big smile on my face.  His name will and always will be carried on 💙  Anyway I'm getting very deep here but just wanted to come on and say I'm so glad I followed my dream  and didn't let fear hold me back because life is funny and you just don't know what's around the corner 💖💖
        </p>
        </p>
      </div>
    </div>
  );
};

export default About;
